/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, hideHeader, removePadding }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {hideHeader === true ? null : (
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      )}
      <div className={`main-center-wrapper ${removePadding === true ? "no-padding" : ""}`}>
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.defaultProps = {
  hideHeader: false,
  removePadding: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool,
  removePadding: PropTypes.bool,
}

export default Layout
