import React from "react"
import { Helmet } from "react-helmet"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"
import "../../components/shinel.css"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PosterImage from "../../components/shinel-poster"

const PosterSlider = () => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    swipeToSlide: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <PosterImage posterIndex={1} />
        </div>
        <div>
          <PosterImage posterIndex={2} />
        </div>
        <div>
          <PosterImage posterIndex={3} />
        </div>
      </Slider>
    </div>
  );
};

const ReserveButton = () => {
  return (
    <p className={`rabelais-button-wrapper`}>
      <a href="https://forms.gle/jbhicMsh69xPqW4d6" className={`rabelais-dark-button`}>
        <strong>예매하기</strong>
      </a>
    </p>
  );
}

const OutlinkText = ({ href, children }) => {
  return (
    <a
      target="_blank" rel="noopener noreferrer"
      className={`rabelais-dark-link`}
      href={href}
    >
      <strong>{children}</strong>
    </a>
  );
}


const IndexPage = () => (
  <Layout hideHeader={true} removePadding={true}>
    <SEO
      title={"연극 〈외투 Шинель〉"}
      titleTemplate={`%s`}
      description={`2025.1.16-26 혜화동1번지
      서점극장 라블레 제4회 정기공연

      일시 2025. 1. 16 - 26. | 화 - 금 20시 | 토 19시 | 일 16시
      장소 연극실험실 혜화동1번지
      러닝타임 90분
      관람등급 12세 이상
      티켓가격 전석 3만원
      문의 mail@rabelais.kr`}
      image={`/shinel-poster2.jpg`}
    />
    <Helmet>
      <style>{`body { background-color: black; }`}</style>
    </Helmet>
    <div className={`poster-main-wrapper`}>
      <PosterSlider />
      <div style={{height:`2rem`}}/>
      {/*<div className={`poster-gradient-background main-padding`}>
        <br />
        <br />
        <br />
        <br />
        <br />
        <h1>연극 외투</h1>
        <p>
          일정&nbsp;&nbsp;<strong>2025.1.16(목) ~ 26(일)</strong>
          <br />
          시간&nbsp;&nbsp;<strong>화~금 20시 | 토 19시 | 일 16시</strong>
          <br />
          장소&nbsp;&nbsp;<strong>연극실험실 혜화동1번지</strong>
          <br />
          러닝타임&nbsp;&nbsp;<strong>90분</strong>
          <br />
          관람등급&nbsp;&nbsp;<strong>12세이상</strong>
          <br />
          티켓가격&nbsp;&nbsp;<strong>30,000원</strong>
        </p>
	<br />
        <br />
	<br />
	<br />
        <br />
	<br />
      </div>*/}
      <PosterImage posterIndex={5} />
      <br />
      <br />
      <ReserveButton />
      <br />
      <br />
      <p>
        극장 위치
	<br />
        서울 종로구 창경궁로35길 7
        <br />
	<OutlinkText href="https://map.naver.com/p/entry/place/21107009?c=15.00,0,0,0,dh">▶네이버 지도에서 보기</OutlinkText>
        {/*<a
          target="_blank" rel="noopener noreferrer"
          style={{ color: "black", textDecorationLine: "none" }}
          href="https://map.naver.com/p/entry/place/21107009?c=15.00,0,0,0,dh"
        >
          <strong>▶네이버 지도에서 보기</strong>
        </a>*/}
	<br />
	<OutlinkText href="https://map.kakao.com/?urlX=500301&urlY=1135232&urlLevel=3&itemId=19149691&q=%EC%97%B0%EA%B7%B9%EC%8B%A4%ED%97%98%EC%8B%A4%20%ED%98%9C%ED%99%94%EB%8F%991%EB%B2%88%EC%A7%80&srcid=19149691&map_type=TYPE_MAP">▶카카오맵에서 보기</OutlinkText>
        {/*<a
          target="_blank" rel="noopener noreferrer"
          style={{ color: "black", textDecorationLine: "none" }}
          href="https://map.kakao.com/?urlX=500301&urlY=1135232&urlLevel=3&itemId=19149691&q=%EC%97%B0%EA%B7%B9%EC%8B%A4%ED%97%98%EC%8B%A4%20%ED%98%9C%ED%99%94%EB%8F%991%EB%B2%88%EC%A7%80&srcid=19149691&map_type=TYPE_MAP"
        >
          <strong>▶카카오맵에서 보기</strong>
        </a>*/}
        <br />
        주차불가
	<br />
	<br />
        문의 전화{`  `}
	<OutlinkText href="tel:+82-10-6706-9749">010-6706-9749</OutlinkText>
        {/*<a
          style={{ color: "black", textDecorationLine: "none" }}
          href="tel:+82-10-6706-9749"
        >
          <strong>010-6706-9749</strong>
        </a>*/}
        <br />
        이메일{`  `}
	<OutlinkText href="mailto:mail@rabelais.kr">mail@rabelais.kr</OutlinkText>
        {/*<a
          style={{ color: "black", textDecorationLine: "none" }}
          href="mailto:mail@rabelais.kr"
        >
          <strong>mail@rabelais.kr</strong>
        </a>*/}
	<br />
	<br />
        <br />
        <br />
      </p>
    </div>
  </Layout>
)

export default IndexPage
