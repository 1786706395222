import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const PosterImage = ({ posterIndex, alt, style }) => {
  const { allImageSharp } = useStaticQuery(graphql`
    query {
      allImageSharp {
        nodes {
          fluid(maxWidth: 1506) {
            originalName
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const index = posterIndex < 0 ? 0 : posterIndex > 4 ? 4 : posterIndex;
  const filename = `shinel-poster${index}.jpg`;
  const fluid = allImageSharp.nodes.find(n => n.fluid !== null && n.fluid.originalName === filename)
    .fluid;

  return <Img fluid={fluid} alt={alt} style={style} />;
}

PosterImage.defaultProps = {
  posterIndex: 0,
};

PosterImage.propTypes = {
  posterIndex: PropTypes.number,
}

export default PosterImage;
